import React from 'react'
import {BiArrowFromBottom} from 'react-icons/bi'
import {AiFillLinkedin, AiFillFacebook, AiOutlineTwitter, AiOutlineInstagram} from 'react-icons/ai'
import './Footer.css'
import { BsFillCalendarEventFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { BsFillTelephoneFill } from "react-icons/bs";

const Footer = () => (
  
  <>

      <footer id="footer" class="bg-gray-800 py-16">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp" data-wow-delay="0.2s">
              <div class="mx-3 mb-8">
                <div class="footer-logo mb-3">
                  <img src="/assets/images/hacc-new-logo.png"  alt=""/>
                </div>
                <p class="text-gray-300 mb-4">Promoteur des Marques Cel'or et Savon Fak.</p>

                <ul class="social-icons flex justify-start">
                  <li class="mx-2">
                    <a href="#"
                      class="footer-icon hover:bg-indigo-500">
                      <i class="lni lni-facebook-original" aria-hidden="true"></i>
                      <AiFillFacebook />
                    </a>
                  </li>
                  <li class="mx-2">
                    <a href="#"
                      class="footer-icon hover:bg-blue-400">
                      <i class="lni lni-twitter-original" aria-hidden="true"></i>
                      <AiOutlineTwitter />
                    </a>
                  </li>
                  <li class="mx-2">
                    <a href="#"
                      class="footer-icon hover:bg-red-500">
                      <i class="lni lni-instagram-original" aria-hidden="true"></i>
                      <AiOutlineInstagram />
                    </a>
                  </li>
                  <li class="mx-2">
                    <a href="#"
                      class="footer-icon hover:bg-indigo-600">
                      <i class="lni lni-linkedin-original" aria-hidden="true"></i>
                      <AiFillLinkedin />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp" data-wow-delay="0.4s">
              <div class="mx-3 mb-8">
                <h3 class="font-bold text-xl text-white mb-5">Company</h3>
                <ul>
                  <li><a href="#" class="footer-links">Press Releases</a></li>
                  <li><a href="#" class="footer-links">Mission</a></li>
                  <li><a href="#" class="footer-links">Strategy</a></li>
                </ul>
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp" data-wow-delay="0.6s">
              <div class="mx-3 mb-8">
                <h3 class="font-bold text-xl text-white mb-5">About</h3>
                <ul>
                  <li><a href="#" class="footer-links">Career</a></li>
                  <li><a href="#" class="footer-links">Team</a></li>
                  <li><a href="#" class="footer-links">Clients</a></li>
                </ul>
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp" data-wow-delay="0.8s">
              <div class="mx-3 mb-8">
                <h3 class="font-bold text-xl text-white mb-5">Contact Info</h3>
          
                <ul>
                <li className='text-white flex flex-row content-center mb-2 '> 
                  <span className='mt-2 hover:text-blue-600'> <BsFillTelephoneFill /> </span>

                  <a className='mx-3 footer-links' href="#home" >Téléphone fixe: (00237) 691 543 169</a>
                </li>
                <li className='text-white flex flex-row content-center mb-2'>   
                <span className='mt-2 hover:text-blue-600'> <ImLocation2 /> </span>

                  <a className='mx-3 footer-links' href="#wgpt3">Localisation: Bali, Rue Des Mangiers</a>
                </li>
                <li className='text-white flex flex-row content-center mb-2'>
                  <span className='mt-2 hover:text-blue-600'> <BsFillCalendarEventFill /> </span>

                  <a className='mx-3 footer-links' href="#possibility">Lun - Dim: 24H/24 7J/7</a>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>     
      </footer> 

      <section class="bg-gray-800 py-6 border-t-2 border-gray-700 border-dotted">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full text-center">
            <p class="text-white">Designed and Developed by <a class="text-white duration-300 hover:text-blue-600" href="https://tailwindtemplates.co" rel="nofollow">HACC SARL</a> and <a class="text-white duration-300 hover:text-blue-600" href="https://uideck.com" rel="nofollow">SET</a></p>
          </div>
        </div>
      </div>
    </section>   

    <a href="#" class="back-to-top w-10 h-10 fixed bottom-0 right-0 mb-5 mr-5 flex items-center justify-center rounded-full bg-blue-600 text-white text-lg z-20 duration-300 hover:bg-blue-400">
      <BiArrowFromBottom />
    </a>

    
  
  </>


  
);


export default Footer