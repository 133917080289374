import React, { useEffect } from 'react'
import "./Testimonials.css"
import WOW from 'wowjs';

function Testimonials() {


    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])




  return (
    <section id="testimonial" class="testimonial-area py-120">
        <div class="container">            
            <div class="justify-center row">
                <div class="w-full mx-4 lg:w-1/2">
                    <div class="pb-10 text-center section-title wow fadeInUp">
                        <h4 class="title">Temoignage</h4>
                        <p class="text">Nos clients disent!</p>
                    </div> 
                </div>
            </div> 
            
            <div class="row">
                <div class="w-full">
                    <div class="row testimonial-active ">
                        <div class="w-full lg:w-1/3 wow fadeInUp">
                            <div class="text-center single-testimonial">
                                <div class="testimonial-image">
                                    <img src="/assets/images/team/team-01.png" alt="Author"/>
                                </div>
                                <div class="testimonial-content">
                                    <p class="pb-5 mb-6 border-b border-gray-300">La meilleure huile du marché, La qualité est leur matière première. Vraiment de l'or dans vos plats !</p>
                                    <h6 class="text-lg font-semibold text-gray-900">Isabela Megane</h6>
                                    <span class="text-sm text-gray-700">Commercant</span>
                                </div>
                            </div> 
                        </div>
                        <div class="w-full lg:w-1/3 wow fadeInUp" data-wow-delay=".5s">
                            <div class="text-center single-testimonial">
                                <div class="testimonial-image">
                                    <img src="/assets/images/team/team-02.png" alt="Author"/>
                                </div>
                                <div class="testimonial-content">
                                    <p class="pb-5 mb-6 border-b border-gray-300">Ils sont très fiables et Connaissent le Travail</p>
                                    <h6 class="text-lg font-semibold text-gray-900">Edgard Gerard</h6>
                                    <span class="text-sm text-gray-700">Fourniseur</span>
                                </div>
                            </div> 
                        </div>
                        <div class="w-full lg:w-1/3 wow fadeInUp" data-wow-delay="1s">
                            <div class="text-center single-testimonial">
                                <div class="testimonial-image">
                                    <img src="/assets/images/team/team-03.png" alt="Author"/>
                                </div>
                                <div class="testimonial-content">
                                    <p class="pb-5 mb-6 border-b border-gray-300">A L’écoute des besoins de ses consommateur, ils Savent toujours ce qu’il nous faut !</p>
                                    <h6 class="text-lg font-semibold text-gray-900"> Fiona Elisabet </h6>
                                    <span class="text-sm text-gray-700">cliente</span>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div> 
        </div> 
    </section>

  )
}

export default Testimonials