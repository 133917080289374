import React, { useEffect, useState } from 'react'
import "./InfoBar.css"
import { BsFillCalendarEventFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useLocation } from 'react-router-dom'


function InfoBar() {

    const location = useLocation();
    console.log(location.pathname);
    
    let hacc__infobar1={};
    
    if(location.pathname==="/"){
      hacc__infobar1 = {
        display:"none"
      };
      
    }

  const Menu =() => (

  
    

    <>
      <p> 
        <span > <BsFillTelephoneFill /> </span>

        <a className='iconT' href="#home" >Téléphone fixe: (00237) 691 543 169</a>
      </p>
      <p>   
      <span > <ImLocation2 /> </span>

        <a className='iconT' href="#wgpt3">Localisation: Bali, Rue Des Mangiers.</a>
      </p>
      <p>
        <span > <BsFillCalendarEventFill /> </span>

        <a className='iconT' href="#possibility">Lun - Dim: 24H/24 7J/7</a>
      </p>
    


      
    </>
  )



  return (
    <div className='hacc__infobar' style={hacc__infobar1}>
    <div className="hacc__infobar-links">
   
       <div className="hacc__infobar-links_container">
       <Menu />
       </div>
    </div>
    
  </div> 
  )
}

export default InfoBar