import React from 'react'
import { Link } from 'react-router-dom'

function EventItem(
    {
    event: {
        id,
        cover,
        title,
        shortDesc,
    },balance }) {
  return (
    <>
        <section class="py-5">
            <div class="container wow fadeInUp" data-wow-delay="0.2s">
                <div class="row ">
                    <div class="col-12 ">
                    <div class="card card-span mb-3 shadow-2xl"  >
                        <div class="card-body fadeInUp py-0">
                        <div class="row justify-content-center " >
                            <div class={`col-md-5 col-xl-7 col-xxl-8 g-0 ${balance}`}><img class="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-start rounded-md-top-0" src={cover} alt="Celor Au Friends Food" /></div>
                            <div class="col-md-7 col-xl-5 col-xxl-4 p-4 p-lg-5">
                              <h1 class="card-title mt-xl-5 mb-4 text-2xl font-bold slideInRight"> Cel'or &  <span class="text-yellow-500"> {title} </span></h1>
                              <p class="fs-1 slideInLeft"> {shortDesc}</p>
                              <div class="d-grid bottom-0"> <Link to={`/evenements/${id}`} className="btn btn-lg bg-yellow-400 mt-xl-6"> Voir Plus <i class="fas fa-chevron-right ms-2"></i></Link></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        </section>
    </>
  )
}

export default EventItem