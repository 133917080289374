import React from 'react';
import './App.css';
import { Home,Footer, ContactUs, About, OurProducts, Events, Gastronomie, Order, Products, SingleReciepe, File404 } from './containers';
import {Navbar,InfoBar} from './components'
import {Routes, Route, useLocation} from "react-router-dom"
import SingleEvent from './containers/singleEvent/SingleEvent';


function App() {




  let spaced={};
  let abso={};

  const location = useLocation();

  if(location.pathname!="/"){

    abso={
      position:"absolute",
    }
    
  }else{
    spaced ={
      paddingTop:0,
    }
  }



  return (
    <div className="App" style={{position:"relative",}}>
      <InfoBar style={abso} />
      <Navbar />
      <div id='modifyPadding' style={spaced}>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/contact' element={<ContactUs />}/>
          <Route path='/apropos' element={<About />}/>
          <Route path='/produits' element={<OurProducts />}/>
          <Route path='/produits/:id' element={<Products />}/>
          <Route path='/evenements' element={<Events />}/>
          <Route path='/contact' element={<ContactUs />}/>
          <Route path='/gastronomie' element={<Gastronomie />}/>
          <Route path='/commande' element={<Order />}/>
          <Route path='/recette/:id' element={<SingleReciepe />}/>
          <Route path='/evenements/:id' element={<SingleEvent />}/>
          <Route path='/evenements/:id' element={<SingleEvent />}/>
          <Route path="*" element={<File404 />} />

        </Routes>
      </div>
      <Footer />

    
    </div>
  );
}

export default App;
