import React from 'react'
import { Link } from 'react-router-dom'

function ReciepeItem(  
    {
        balance,
        reciepe: {
        id,
        cover,
        title,
        shortTitle,
        title2,
    }, }) {
  return (
    <>
        <section class="pb-5 pt-8">
            <div class="container ">
                <div class="row ">
                    <div class="col-12 ">
                    <div class="card card-span mb-3 shadow-2xl wow fadeInUp"  data-wow-delay="0.2s">
                        <div class="card-body fadeInUp py-0">
                        <div class="row justify-content-center " >
                            <div class={`col-md-5 col-xl-7 col-xxl-8 g-0 ${balance} `}><img class="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src={cover} alt="Celor Au Friends Food" /></div>
                            <div class="col-md-7 col-xl-5 col-xxl-4 p-4 p-lg-5">
                              <h1 class="card-title mt-xl-5 mb-4 text-2xl font-bold slideInRight"> {title} <span class="text-yellow-500"> {title2} </span></h1>
                              <p class="fs-1 slideInLeft"> Place en cuisine avec Cel’or et son menu du jour  <span className="font-bold">{shortTitle}</span>...</p>
                              <div class="d-grid bottom-0"> <Link to={`/recette/${id}`} className="btn btn-lg bg-blue-600 mt-xl-6"> Voir Plus <i class="fas fa-chevron-right ms-2"></i></Link></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        </section>
    </>
  )
}

export default ReciepeItem