import React, { useEffect } from 'react'
import './HomeAbout.css'
import WOW from 'wowjs';

function HomeAbout() {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

  return (
    <div className='hacc__homeAbout'>
              <section className="pt-10 pb-10">

                <div className="container">
                    <div className="flex flex-wrap justify-between">
                        <div className="w-full text-center  md:w-1/2">
                            <h2 className="text-4xl font-bold leading-snug text-gray-700 mb-10 wow fadeInUp" data-wow-delay=".1s">À Propos De Nous</h2>

                            <p className="wow slideInLeft text m-20" data-wow-delay=".3s">HUILERIE ALIMENTAIRE ET CHIMIQUE DU CAMEROUN <br />
                            Est un complexe Agro-industrielle, qui est spécialisé dans la transformation d’Huile Végétale...</p>
                            
                            <a class="wow slideInLeft btn gradient-btn rounded-full my-2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" data-wow-delay=".5s" href="#">Lire Plus</a>
                        </div>
                        <div className="img-fluid wow slideInRight mx-auto w-full md:w-1/2"><img className="img-fluid mb-4" src="/assets/images/home/hero.svg" width="550" alt="" /></div>
                    </div>

                </div>

            </section>

    </div>
  )
}

export default HomeAbout