import React, { useEffect, useState } from 'react'
import './Gastronomie.css'
import WOW from 'wowjs';
import { ReciepeItem } from '../../components';

import {reciepesList} from '../../config/reciepeData'

function Gastronomie() {


  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <div>
        <div className="container">
            <div class="justify-center row">
                <div class="w-full mx-4 lg:w-1/2">
                    <div class="pb-9 text-center section-title wow fadeInUp">
                        <h4 class="title">Quelques repas pour Vous</h4>
                        <p class="text">Ici Vous avez des recette pour votre allimentation avec Cel’Or!</p>
                    </div>
                </div>
            </div> 
        </div>

      {reciepesList.map((reciepe) => (
        <ReciepeItem reciepe={reciepe}  key={reciepe.id} balance={reciepe.id%2==0?"order-md-1":" "}  />
      ))}

    </div>
  )
}

export default Gastronomie