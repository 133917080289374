import React, { useEffect, useState } from 'react'
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri'
import {AiFillLinkedin, AiFillFacebook, AiOutlineTwitter, AiOutlineInstagram} from 'react-icons/ai'
import './Navbar.css'
import { Link, useLocation } from 'react-router-dom'

const Navbar = () => {
  let location =useLocation();
  const [toggleMenu, setToggleMenu] = useState(false)
  useEffect(() => {
    setToggleMenu(false);
  }, [location])
  



  let hacc__navbar1={};
  let fixedNav={};
  let anim_border,anim_border1,anim_border2,anim_border3,anim_border4,anim_border5,anim_border6={};




  hacc__navbar1 = {
    position: "absolute",
    zIndex:1,
    width: "100%",
    background: "transparent",

    top: 40,
    right: 0,
    left: 0,
    zIndex: 1030,

  };


  if(location.pathname==="/"){

    hacc__navbar1={
      position: "absolute",
      zIndex:1,
      width: "100%",
      background: "transparent",

      top: 0,
      right: 0,
      left: 0,
      
      zIndex: 1030,
    }
         
    anim_border={
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
      display:"none",
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
      display:"none",
    } 
    anim_border6={
      display:"none",
    }

  } else
  if(location.pathname.includes("apropos")){
     
    anim_border={
      display:"none",
    } 
    anim_border1={
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
      display:"none",
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
      display:"none",
    } 
    anim_border6={
      display:"none",
    }
    
  } else
  if(location.pathname.includes("produits")){
       
    anim_border={
      display:"none",
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
    } 
    anim_border3={
      display:"none",
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
      display:"none",
    } 
    anim_border6={
      display:"none",
    }
  } else
  if(location.pathname.includes("evenements")){
       
    anim_border={
      display:"none",
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
      display:"none",
    } 
    anim_border6={
      display:"none",
    }
  } else if(location.pathname.includes("contact")){
       
    anim_border={
      display:"none",
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
      display:"none",
    } 
    anim_border4={
    } 
    anim_border5={
      display:"none",
    } 
    anim_border6={
      display:"none",
    }
  } else
  if(location.pathname.includes("gastronomie")){
       
    anim_border={
      display:"none",
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
      display:"none",
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
    } 
    anim_border6={
      display:"none",
    }
  } else
  if(location.pathname.includes("commande")){
       
    anim_border={
      display:"none",
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
      display:"none",
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
      display:"none",
    } 
    anim_border6={
    }
  }else
  if(location.pathname.includes("recette")){
       
    anim_border={
      display:"none",
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
      display:"none",
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
    } 
    anim_border6={
      display:"none",
    }
  } else
  if(location.pathname.includes("evenements")){
       
    anim_border={
      display:"none",
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
      display:"none",
    } 
    anim_border6={
      display:"none",
    }
  }else{
     
    anim_border={
      display:"none",
    } 
    anim_border1={
      display:"none",
    } 
    anim_border2={
      display:"none",
    } 
    anim_border3={
      display:"none",
    } 
    anim_border4={
      display:"none",
    } 
    anim_border5={
      display:"none",
    } 
    anim_border6={
      display:"none",
    }
  }



  fixedNav ={
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1030,
  }
 


  const Menu =() => (
    <>
      <p className=''>
        <Link to="/" className="hover:text-red-600 text-xl " >Acceuil</Link>
        <div style={anim_border} />
      </p>
      <p>
        <Link to="/apropos" className="hover:text-red-600 text-xl font-extrabold">Apropos</Link>
        <div style={anim_border1} />
      </p>
      <p>
        <Link to="/produits" className="hover:text-red-600 text-xl font-extrabold">Produit</Link>
        <div style={anim_border2} />
      </p>
      <p>
        <Link to="/evenements" className="hover:text-red-600 text-xl font-extrabold">Evenements</Link>
        <div style={anim_border3} />
      </p>
      <p>
        <Link to="/contact" className="hover:text-red-600 text-xl font-extrabold">Contact</Link>
        <div style={anim_border4} />
      </p>
      <p>
        <Link to="/gastronomie" className="hover:text-red-600 text-xl font-extrabold">Gastronomie</Link>
        <div style={anim_border5} />
      </p>
      <p>
        <Link to="/commande" className="hover:text-red-600 text-xl font-extrabold">Mon Panier</Link>
        <div style={anim_border6} />
      </p>
    </>
  )

  return (

    <div className='hacc__navbar fixed-top' style={hacc__navbar1}>
      <div className="hacc__navbar-links">
         <div className="hacc__navbar-links_logo">
          <img src="/assets/images/logo-hacc.jpeg" alt="logo" />
         </div> 
      </div>

      <div className="hacc__navbar-links_container">
          <Menu />
      </div>
      <div className="hacc__navbar-sign">
        <p>
          Sign in
        </p>
        <button type='button'>
          Sign up
        </button>
      </div>

      <div className="hacc__navbar-menu" style={{zIndex:20}} >
        {
        toggleMenu
          ? <RiCloseLine color='#070707' size={27} onClick={()=>setToggleMenu(false)} />
          : <RiMenu3Line color='#070707' size={27} onClick={()=>setToggleMenu(true)} />
        }

        {
          toggleMenu && (
            <div className="hacc__navbar-menu_container scale-up-center">
              <div className="hacc__navbar-menu_contaner-links" >
                <Menu  />

                <div className="hacc__navbar-menu_container-links-sign">
                  <p>
                    Sign in
                  </p>
                  <button type='button'>
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          )
        }
      </div>
      
    </div> 
  )
}

export default Navbar