import React, { useEffect, useState } from 'react'
import './SingleReciepe.css'
import {FaNutritionix} from 'react-icons/fa'
import {VscDebugStepInto} from 'react-icons/vsc'
import {reciepesList} from '../../config/reciepeData'
import { Link, useParams } from 'react-router-dom'
import { EmptyList } from '../../components'

function SingleReciepe() {

  const { id } = useParams();
  const [reciepe, setReciepe] = useState(null)

  useEffect(() => {
    let reciepe = reciepesList.find((reciepe) => reciepe.id === parseInt(id));
    if (reciepe) {
      setReciepe(reciepe);
    }
  }, [id]);



  return (
    <div className='mt-5 mb-5 m-4'>

            <Link className='blog-goBack' to='/gastronomie'>
              <span> &#8592;</span> <span>Retour</span>
            </Link>

        {reciepe ? (
          <>
            <div className="container">
                <div class="justify-center row">
                    <div class="w-full mx-4 lg:w-1/2">
                        <div class="pb-9 text-center section-title wow fadeInUp">
                            <h4 class="title"> {reciepe.title} <span className="font-bold text-yellow-400"> {reciepe.title2} </span> </h4>
                            <p class="text">Place en cuisine avec Cel’or et son menu du jour <span className="font-bold">{reciepe.shortTitle}</span> </p>
                        </div>
                    </div>
                </div> 
            </div>
            

            <div className="container pb-5">
                    <div class="grid grid-rows-4 grid-cols-4 grid-flow-col gap-4 wow fadeInUp">
                      <div class="row-span-4 col-span-3 g-0 order-1 order-md-1"><img class="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src={reciepe.cover} alt="Celor Au Friends Food" /></div>
                      <div class="row-span-2 col-span-2 g-0 order-2 order-md-2"><img class="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src={reciepe.cover2[0]} alt="Celor Au Friends Food" /></div>
                      <div class="row-span-2 col-span-2 g-0 order-3 order-md-3"><img class="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src={reciepe.cover2[1]} alt="Celor Au Friends Food" /></div>
                    </div>
            </div>

            <div className="container">
                <div class="justify-center row">
                    <div class="pb-1 wow fadeInUp text-left">
                        <h4 class="text-4xl font-bold">Préparation: {reciepe.preparationTime}</h4>
                        <p class="text-4xl font-bold">Cuisson: {reciepe.cookTime}!</p>
                        <p class="text-4xl font-bold">Ingrédients:</p>
                    </div>
                </div> 
            </div>

            <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
              <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 ml-5 md:gap-x-12 md:gap-y-16">
                {reciepe.ingredients.map((ingredient) => (
                  <div key={ingredient.ingredient} className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                    <div className="flex h-8 w-8 items-center justify-center rounded-xl bg-indigo-500 text-white sm:shrink-0">
                    <FaNutritionix />
                    </div>
                    <div className="sm:min-w-0 sm:flex-1">
                      <p className="text-lg font-semibold leading-8 text-gray-900">{ingredient.ingredient}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <p class="mt-20 text-4xl font-bold">Etapes :</p>

            <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
              <div className="flex flex-col  md:grid-cols-2 ml-5">
                {reciepe.steps.map((step) => (
                  <div key={step.text} className="relative flex flex-col items-center sm:flex-row md:flex-col lg:flex-row ">
                    <div className="flex h-6 w-6 items-center self-center justify-center rounded-full bg-indigo-500 text-white sm:shrink-0">
                      <VscDebugStepInto />
                    </div>
                    <div className="sm:min-w-0 sm:flex-1 pr-4 pl-2">
                      <p className="mt-2 text-base leading-7 text-gray-600">{step.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
      ) : (
        <EmptyList />
      )}

    </div>
  )
}

export default SingleReciepe