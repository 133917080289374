import React, { useEffect } from 'react'
import "./About.css"
import WOW from 'wowjs';

function About() {


    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])




  return (
    <div class="container my-24 px-6 mx-auto" id='about'>

        <div className="container">
            <div class="justify-center row">
                <div class="w-full mx-4 lg:w-1/2">
                    <div class="pb-9 text-center section-title wow fadeInUp">
                        <h4 class="title">Apropos de Nous</h4>
                        <p class="text">Ici Vous avez plus de details sur nous!</p>
                    </div>
                </div>
            </div> 
        </div>

        <section class="mb-32">
    
            <div class="flex flex-wrap wow fadeInUp">
                <div class="grow-0 shrink-0 basis-auto w-full lg:w-5/12 mb-12 lg:mb-0">
                <div class="flex lg:py-12">
                    <img src="/assets/images/about/aboutBg2.jpeg" class="w-full rounded-lg shadow-lg" id="cta-img-nml-50" style={{zIndex: 2}}  alt="" />
                </div>
                </div>
        
                <div class="grow-0 shrink-0 basis-auto w-full lg:w-7/12" >
                <div 
                    class="bg-yellow-500 h-full rounded-lg p-6 lg:pl-12 text-white flex items-center text-center lg:text-left">
                    <div class="lg:pl-12">
                    <h2 class="text-3xl font-bold mb-6 text-white">Presentation Du Promoteur..</h2>
                    <p class="mb-3 pb-2 lg:pb-0 text-white">
                        Issue d’une famille prolétaire, Monsieur FAKAM EMMANUEL CÉLESTIN vois le jour le 14 Février 1974 au Cameroun, dans la ville de Douala.
                    </p>
        
                    <p className='text-white'> 
                        Il s’est imprégné dès sa sortie de l’école dans plusieurs industries de transformations d’envergure tel que ALUCAM, BRASSERIE DU CAMEROUN, … avant d’occuper le poste de Directeur d ‘Usine dans la société GANDOUR S.A, qui exerçait dans la fabrication des Savons de toilette.
                        En plus de son profil d’ingénieur, Monsieur Célestin Emmanuel est un consultant chevronné en élaboration et gestion des projets industrielle, il a été directeur du projet dans la conception, le montage et le lancement de plusieurs industries agroalimentaire au Cameroun.
                        Fort de ses années d’expériences en génie industrielle et de gestion de plusieurs projets, Emmanuel Célestin FAKAM se lance dans l’entreprenariat dès les années 2004 par la création d’une palmisterie ultra moderne d’une capacité de 20 Tonnes/jour dans la zone industrielle de Bassa, et devient fournisseur principal dans les sociétés de référence du secteur des Oléagineux tel que :CCC, AZUR SOC…
                        on goût poussé pour l’entreprenariat et dans le but d’exploiter au mieux sa production, en Huile de Palme qui est un produit incontournable dans la production en Savonnerie, c’est ainsi qu’il va être poussé à la création d’une industrie de savon de ménage dénommé HACC Sarl.
                        Courageux et patient, et dans l’objectif d’optimiser au mieux sa production de savon, il se lance dans le projet de la création d’une industrie de Raffinage moderne d’HPB d’une capacité de 250 Tonnes jour, soit 200 Tonnes d’oléine/Jour dans la zone de Yassa
                        L’année 2010 fut  l’année lumière de ce visionnaire. Car son entreprise de Savonnerie  fut l’une première a posséder un système automatiser. En 2012 son industrie de raffinerie  vois finalement le jour et en 2017 naquis sa marque de produit CEL’OR.
                    </p>
                    </div>
                </div>
                </div>
            </div>
        </section>


        <section class="mb-32">
    
            <div class="flex flex-wrap wow fadeInUp">
                <div class="grow-0 shrink-0 basis-auto w-full lg:w-7/12" >
                    <div 
                        class="bg-yellow-500 h-full rounded-lg p-6 lg:pl-12 text-white flex items-center text-center lg:text-left">
                        <div class="lg:pl-12 ">
                            <h2 class="text-3xl font-bold mb-6 text-white">Qui Sommes Nous ?.</h2>
                            <p class="mb-3 pb-2 lg:pb-0 text-white">Est un complexe Agro-industrielle, qui est spécialisé dans la transformation d’Huile Végétale.

                            </p>
                
                           
                            <p className='text-white'>
                                Créée en 2006, elle s’étend sur deux zones distinctes,dont une Savonnerie à PK8, ESG Campus C, et une Raffinerie d’huile de palme brute du côté de YASSA.
                                Elle est membre de l’Association des Raffineurs de Oléagineux du Cameroun en abrégé ASROC.

                                HACC Sarl, a pour partenaire exclusif en fourniture d’huile de palme brute SOCAPALM. ce qui justifie la qualité supérieur de ses produits
                                Nos produits respectent les standards des normes et de qualités imposés par la république du Cameroun...
                            </p>
                        </div>
                    </div>
                </div>
                <div class="grow-0 shrink-0 basis-auto w-full lg:w-5/12 mt-12  lg:mb-0">
                    <div class="flex lg:py-12">
                        <img src="/assets/images/about/aboutBg.jpeg" class="w-full rounded-lg shadow-lg" id="cta-img-nml-50" style={{zIndex: 2}}  alt="" />
                    </div>
                </div>
            </div>
        </section>
  
    </div>
   
  )
}

export default About