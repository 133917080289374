import React, { useEffect, useState } from 'react'
import './Events.css'
import WOW from 'wowjs';
import { Link } from 'react-router-dom';
import { EventItem } from '../../components';

import {eventsList} from '../../config/eventsData'


function Events() {


    // const [eventsList, setBlogs] = useState(eventsList);

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])


  return (
    <>
        <section class="pb-5 pt-5">

            <div className="container">
                <div class="justify-center row">
                    <div class="w-full mx-4 lg:w-1/2">
                        <div class="pb-9 text-center section-title wow fadeInUp">
                            <h4 class="title">Nos Activites</h4>
                            <p class="text">Ici Vous sont illustrer les Activite entrepris par HACC pour la communaute!</p>
                        </div>
                    </div>
                </div> 
            </div>

          

        </section>


      {eventsList.map((event) => (
        <EventItem event={event} key={event.id} balance={event.id%2==0?" order-md-1 ":" "} />
      ))}

        
    </>
  )
}

export default Events