export const reciepesList = [
    {
        id: 1,
        title: 'Cel’or et son ',
        title2: 'Moussaka de mouton',
        shortTitle:"le Moussaka  de mouton!",
        preparationTime: '30 Minutes',
        cookTime: '30 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"9 aubergines"
            },
            {
              img_url: "",
              ingredient:"1.2 kg de viande de mouton hachée"
            },
            {
              img_url: "",
              ingredient:"2 oignons"
            },
            {
              img_url: "",
              ingredient:"3 oeufs"
            },
            {
              img_url: "",
              ingredient:"30 g de beurre"
            },
            {
              img_url: "",
              ingredient:"Huile Raffinée Cel’or"
            },
            {
              img_url: "",
              ingredient:"2 gousses d’ail"
            },
            {
              img_url: "",
              ingredient:"150 g de champignon coupés en morceaux"
            },
            {
              img_url: "",
              ingredient:"75 cl de coulis de tomate"
            },
            {
              img_url: "",
              ingredient:"3 cuillères à soupe de persil haché"
            },
            {
              img_url: "",
              ingredient:"Poivre"
            },
            {
              img_url: "",
              ingredient:"Sel"
            },
        ],
        steps : [
            {
            text:"Coupez les moitiés d’aubergines dans le sens de la longueur. Faites-les revenir dans l’huile raffinée  5 minutes de chaque côté puis retirez et conservez la peau."
            },
            {
            text:"Hachez la chair."
            },
            {
            text:"Coupez l’oignon et l’ail et mettez les à revenir dans le beurre. Mettez le mouton haché, le coulis de tomate, salez et poivrez. Laissez mijoter 5 min puis ajoutez les morceaux de champignons."
            },
            {
            text:"Hors du feu, mettez les œufs et le persil et mélangez bien le tout."
            },
            {
            text:"Tapissez le fond du plat huilé des peaux d’aubergines. Garnissez les de viande et d’aubergines en alternant les couches. Tassez bien."
            },
            {
            text:"Cuisez au bain-marie durant 50 minutes"
            },
            {
            text:"Démoulez et servez éventuellement avec une sauce tomate épicée."
            },
        ],
        createdAt: '05 aout 2020', 
        cover:'/assets/images/reciepes/celor-mousaka-de-mouton-medium.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-mousaka-de-mouton3.jpeg',
            '/assets/images/reciepes/celor-mousaka-de-mouton3.jpeg'
      ],
    },
    {
        id: 2,
        title: 'Cel’Or et son ',
        title2: 'Porc fumé aux aubergines',
        shortTitle:"du porc fumé aux aubergines!",
        preparationTime: '15 Minutes',
        cookTime: '50 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"500g de porc fumé"
            },
            {
              img_url: "",
              ingredient:"300g d’aubergines"
            },
            {
              img_url: "",
              ingredient:"2 oignons"
            },
            {
              img_url: "",
              ingredient:"1 gousse d’ail"
            },
            {
              img_url: "",
              ingredient:"2 tomates"
            },
            {
              img_url: "",
              ingredient:"Céleri"
            },
            {
              img_url: "",
              ingredient:"Gingembre"
            },
            {
              img_url: "",
              ingredient:"Sel"
            },
            {
              img_url: "",
              ingredient:"1/4 d’huile raffinée Cel’or"
            },
        ],
        steps : [
            {
            text:"Préparez et faites cuire votre viande"
            },
            {
            text:"Lavez les aubergines."
            },
            {
            text:"Faites bouillir les aubergines durant 10 minutes, laissez-les refroidir et émondez-les (enlevez leur peau)."
            },
            {
            text:"Découpez chaque aubergine en quatre morceaux."
            },
            {
            text:"Écrasez l’ail, le céleri et la tomate."
            },
            {
            text:"Hachez finement les oignons."
            },
            {
            text:"Faites chauffer un peu d’huile Cel’or dans une casserole. Faites y revenir les oignons."
            },
            {
            text:"Ajoutez les morceaux de porc fumé et les condiments écrasés et laisser mijoter 10 minutes."
            },
            {
            text:"Ajoutez les aubergines et laissez cuire 30 minutes."
            },
            {
            text:"Rectifiez l’assaisonnement (sel, poivre, cube)."
            },
            {
            text:"Servir avec des pommes, plantain, macabo, igname au choix."
            },
        ],
        createdAt: 'June 03, 2021', 
        cover:'/assets/images/reciepes/celor-porc-fume-medium.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-porc-fume2.jpeg',
            '/assets/images/reciepes/celor-porc-fume2.jpeg'
      ],
    },
    {
        id: 3,
        title: 'Cel’Or et son ',
        title2: 'Maquereau à la poèle.',
        shortTitle:"le Maquereau à la poèle!",
        preparationTime: '1h30 Minutes',
        cookTime: '30 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"Rondelles (5 graines)."
            },
            {
              img_url: "",
              ingredient:"Pèbè (5 graines)."
            },
            {
              img_url: "",
              ingredient:"4 côtés"
            },
            {
              img_url: "",
              ingredient:"7 Clous de girofle."
            },
            {
              img_url: "",
              ingredient:"10 grains de Djansan."
            },
            {
              img_url: "",
              ingredient:"Poivre blanc (10 ou 15 graines)."
            },
            {
              img_url: "",
              ingredient:"1 gousse d’Ail."
            },
            {
              img_url: "",
              ingredient:"2 Oignons"
            },
            {
              img_url: "",
              ingredient:"1 tige de poireau"
            },
            {
              img_url: "",
              ingredient:"Céleri"
            },
            {
              img_url: "",
              ingredient:"Basilic"
            },
            {
              img_url: "",
              ingredient:"1/2 Litre Huile raffinée Cel’or"
            },
            {
              img_url: "",
              ingredient:"Sel ou cube d’assaisonnement"
            },
        ],
        steps : [
            {
            text:"Nettoyez, Rincez et égouttez le poisson. Faites des entailles obliques et parallèles sur les flancs. Laver bien le poisson avec du sel, citron ou vinaigre."
            },
            {
            text:"Écrasez votre , ail, et rondelles puis laisser macérer votre poisson dans ce mélange. Salez et poivrez. Laisser reposer pendant une heure."
            },
            {
            text:"Entre-temps, préparez votre sauce de marinade. Pour se faire, grillez les épices (rondelles, pèbè, 4 côtés, clou de girofle, djansan, poivre blanc) dans une poêle puis écraser à la pierre ou au mixeur."
            },
            {
            text:"Ensuite, mâchurer les condiments (ail, oignons, poireau, céleri, basilic, persil); les mélanger aux épices préalablement écrasées a la pierre. Séparez cette marinade et conservez une partie pour la braise."
            },
            {
            text:"Mettre l’autre partie de la marinade dans une casserole et cuire à feu doux jusqu’à évaporation de l’eau Salez et arrosez d’huile raffinée Cel’or et goûtez l’assaisonnement."
            },
            {
            text:"Verser un filet d’huile végétale dans une poêle et une fois celle-ci bien chaude, poser délicatement le poisson. À feu assez vif, faire cuire le poisson  pendant une minute puis Tourner délicatement le poisson pour cuire son autre face."
            },
            {
            text:"C’est prêt!"
            },
        ],
        createdAt: '19 juin, 2020', 
        cover:'/assets/images/reciepes/celor-maquero-meduim.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-maquero2.jpeg',
            '/assets/images/reciepes/celor-maquero3.jpeg'
      ],
    },
    {
        id: 4,
        title: 'Cel’OR et son ',
        title2: 'Sauté de tripe',
        shortTitle:"Le sauté de tripe de boeuf!",
        preparationTime: '15 Minutes',
        cookTime: '50 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"500 g de tripes fraîches"
            },
            {
              img_url: "",
              ingredient:"1 oignon"
            },
            {
              img_url: "",
              ingredient:"2 clous de girofle"
            },
            {
              img_url: "",
              ingredient:"1 bouquet garni"
            },
            {
              img_url: "",
              ingredient:"1 gousse d’ail"
            },
            {
              img_url: "",
              ingredient:"2  tomates fraîches"
            },
            {
              img_url: "",
              ingredient:"1 blanc de poireau"
            },
            {
              img_url: "",
              ingredient:"1 poivron"
            },
            {
              img_url: "",
              ingredient:"Persil"
            },
            {
              img_url: "",
              ingredient:"1 piment"
            },
            {
              img_url: "",
              ingredient:"Huile 100% végétale Cel’Or"
            },
            {
              img_url: "",
              ingredient:"Sel."
            },
            {
              img_url: "",
              ingredient:"Poivre"
            },
        ],
        steps : [
            {
            text:"Rincez les tripes et mettez-les dans une casserole d’eau froide salée. Ensuite, les porter à ébullition et laissez bouillir 10 min puis égouttez-les."
            },
            {
            text:"Faites chauffer  un peu d’huile végétale Cel’or dans une cocotte-minute de préférence et y faire revenir l’oignon, le blanc de poireau et l’ail émincé. Mélangez. Rajoutez le poivron et les tomates fraîches coupés en dés."
            },
            {
            text:"Ajoutez les tripes, le clou de girofle, le bouquet garni, le persil, le sel et le poivre."
            },
            {
            text:"Fermez la cocotte et laissez cuire 40 min jusqu’à ce que la sauce sèche. Rajouter 3 cuillerée à soupe d’huile raffinée Cel’or. Laisser mijoter encore 2 minutes et servir chaud avec un accompagnement de votre choix."
            },
            {
            text:"Écrasez l’ail, le céleri et la tomate."
            },
            {
            text:"Bon appétit."
            },
        ],
        createdAt: '15 avril 2020', 
        cover:'/assets/images/reciepes/celor-tripes-saute1.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-trippes2.jpeg',
            '/assets/images/reciepes/celor-trippes3.jpeg'
      ],
    },
    {
        id: 5,
        title: 'Cel’or et son ',
        title2: 'Sauté de Légumes aux champignons',
        shortTitle:"le Sauté de légumes aux champignons!",
        preparationTime: '60 Minutes',
        cookTime: '30 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"500g de champignon frais."
            },
            {
              img_url: "",
              ingredient:"500g de viande de bœuf"
            },
            {
              img_url: "",
              ingredient:"100g de Crevettes séchées"
            },
            {
              img_url: "",
              ingredient:"2 gousses d’ail"
            },
            {
              img_url: "",
              ingredient:"6 tomates"
            },
            {
              img_url: "",
              ingredient:"3 oignon"
            },
            {
              img_url: "",
              ingredient:"1/2 d’huile raffinée Cel’or"
            },
            {
              img_url: "",
              ingredient:"piment"
            },
            {
              img_url: "",
              ingredient:"3 cubes"
            },
            {
              img_url: "",
              ingredient:"Sel"
            },
        ],
        steps : [
            {
            text:"Nettoyez avec plusieurs eaux les champignons frais et  vérifier qu’il n’y ai plus de sable à l’intérieur, ensuite faire bouillir avec 1/4 de litre d’eau potable et du sel pendant 10 minute puis essorez et réservez."
            },
            {
            text:"Découper moyennement les feuilles de légume fraiches, les laver et les verser dans de l’eau bouillante avec du sel . Laisser cuire en remuant de temps en temps.Lorsque les légumes sont tendres, les égoutter, les laisser refroidir et les essorer."
            },
            {
            text:"Faites revenir dans une petite quantité d’huile Cel’or  les morceaux de viande pendant 5 minutes. Rajoutez de l’eau à hauteur et laissez mijoter 35 minutes à feu doux."
            },
            {
            text:"Hachez l’oignon, la tomate et l’ail.  ajoutez les crevettes séchées, et réservez."
            },
            {
            text:"Dans une casserole sèche, ajoutez votre huile raffinée Cel’or sans cholestérol et faites revenir les tomates hachées pendant 10 minutes puis ajouté l’oignons, l’ail et les crevettes sèches et laissez mijoter pendant 5 minutes."
            },
            {
            text:"Ajoutez les champignons et la viande laissez mariner pendant cinq minutes avant d’ajouter les légumes, remuez pour mélanger le tout, puis ajustez l’assaisonnement et laissez reposer encore cinq minutes."
            },
            {
            text:"C’est prêt, vous pouvez servir avec de la banane ou un complément de votre choix."
            },
            {
            text:"Bonne appétit."
            },
            {
            text:"Ajoutez les aubergines et laissez cuire 30 minutes."
            },
            {
            text:"Rectifiez l’assaisonnement (sel, poivre, cube)."
            },
            {
            text:"Servir avec des pommes, plantain, macabo, igname au choix."
            },
        ],
        createdAt: '22 Mai 2020', 
        cover:'/assets/images/reciepes/hacc-legumes-saute-medium.jpeg',
        cover2: [
            '/assets/images/reciepes/hacc-legumes-saute2.jpeg',
            '/assets/images/reciepes/hacc-legumes-saute3.jpeg'
      ],
    },
    {
        id: 6,
        title: 'Cel’Or et son ',
        title2: 'Porc fumé aux aubergines',
        shortTitle:"du porc fumé aux aubergines!",
        preparationTime: '15 Minutes',
        cookTime: '50 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"500g de porc fumé"
            },
            {
              img_url: "",
              ingredient:"300g d’aubergines"
            },
            {
              img_url: "",
              ingredient:"2 oignons"
            },
            {
              img_url: "",
              ingredient:"1 gousse d’ail"
            },
            {
              img_url: "",
              ingredient:"2 tomates"
            },
            {
              img_url: "",
              ingredient:"Céleri"
            },
            {
              img_url: "",
              ingredient:"Gingembre"
            },
            {
              img_url: "",
              ingredient:"Sel"
            },
            {
              img_url: "",
              ingredient:"1/4 d’huile raffinée Cel’or"
            },
        ],
        steps : [
            {
            text:"Préparez et faites cuire votre viande"
            },
            {
            text:"Lavez les aubergines."
            },
            {
            text:"Faites bouillir les aubergines durant 10 minutes, laissez-les refroidir et émondez-les (enlevez leur peau)."
            },
            {
            text:"Découpez chaque aubergine en quatre morceaux."
            },
            {
            text:"Écrasez l’ail, le céleri et la tomate."
            },
            {
            text:"Hachez finement les oignons."
            },
            {
            text:"Faites chauffer un peu d’huile Cel’or dans une casserole. Faites y revenir les oignons."
            },
            {
            text:"Ajoutez les morceaux de porc fumé et les condiments écrasés et laisser mijoter 10 minutes."
            },
            {
            text:"Ajoutez les aubergines et laissez cuire 30 minutes."
            },
            {
            text:"Rectifiez l’assaisonnement (sel, poivre, cube)."
            },
            {
            text:"Servir avec des pommes, plantain, macabo, igname au choix."
            },
        ],
        createdAt: '14 Julliet 2020', 
        cover:'/assets/images/reciepes/porc-fume-celor.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-porc-fume2.jpeg',
            '/assets/images/reciepes/celor-porc-fume2.jpeg'
      ],
    },
    {
        id: 7,
        title: 'Cel’Or et son ',
        title2: 'Bouillon de patte de bœuf',
        shortTitle:"bouillon de pattes de bœuf!",
        preparationTime: '20 Minutes',
        cookTime: '3h20 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"2 pattes de bœuf nettoyées et découpées en morceaux,"
            },
            {
              img_url: "",
              ingredient:"Un morceau de gingembre"
            },
            {
              img_url: "",
              ingredient:"Du sel, poivre blanc et du poivre noir, trois pépé, trois rondelles, un demi-verre de djansang,"
            },
            {
              img_url: "",
              ingredient:"Du piment, du poivron, du poireau"
            },
            {
              img_url: "",
              ingredient:"2 grosses tomates demi-mûre"
            },
            {
              img_url: "",
              ingredient:"1/4 d’huile végétal Cel’Or"
            },
        ],
        steps : [
            {
            text:"Dans une cocotte bouillir les morceaux de pattes salés mélangés à l’ail,l’oignon, gingembre écrasés, pendant 3 heures."
            },
            {
            text:"Ajoutez y le djansang,les poivres, le pépé et les rondelles écrasé et y mettre les légumes découpés et le piment entier (ou selon votre choix)."
            },
            {
            text:"Laisser mijoter une dizaine de minutes et ajouter enfin votre huile 100% pure raffinée Cel’Or."
            },
            {
            text:"Goûter l’assaisonnement et c’est prêt!."
            },
            {
            text:"Bonne dégustation!!!."
            },
        ],
        createdAt: '16 avril 2020', 
        cover:'/assets/images/reciepes/celor-bouilon-pates-de-boeuf.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-bouilon-pates-de-boeuf2.jpeg',
            '/assets/images/reciepes/celor-bouilon-pates-de-boeuf2.jpeg'
      ],
    },
    {
        id: 8,
        title: 'Cel’Or et ',
        title2: 'Son Poulet DG',
        shortTitle:"le Poulet DG!",
        preparationTime: '25 Minutes',
        cookTime: '45 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"1 poulet"
            },
            {
              img_url: "",
              ingredient:"4 carottes"
            },
            {
              img_url: "",
              ingredient:"2 poivrons"
            },
            {
              img_url: "",
              ingredient:"2 oignons"
            },
            {
              img_url: "",
              ingredient:"6 tomates demi mûre"
            },
            {
              img_url: "",
              ingredient:"condiment vert"
            },
            {
              img_url: "",
              ingredient:"200g de haricots verts"
            },
            {
              img_url: "",
              ingredient:"12 doigts de plantain mûrs ou pas (selon votre choix)"
            },
            {
              img_url: "",
              ingredient:"2 gousses d’ail"
            },
            {
              img_url: "",
              ingredient:"50g de poivre blanc"
            },
            {
              img_url: "",
              ingredient:"2 rondelles"
            },
            {
              img_url: "",
              ingredient:"12 pépé"
            },
            {
              img_url: "",
              ingredient:"cubes"
            },
            {
              img_url: "",
              ingredient:"piment"
            },
            {
              img_url: "",
              ingredient:"1/2l d’huile raffinée Cel’or"
            },
            {
              img_url: "",
              ingredient:"Sel"
            },
        ],
        steps : [
            {
            text:"Découpez et apprêtez votre poulet puis,  faites bouillir le poulet dans une casserole avec un peu d’eau, un oignon, 1/2poivron et l’ail durant 10 minutes."
            },
            {
            text:"Pendant ce temps, préparez et haches  les carottes en dé et réservez-les."
            },
            {
            text:"Coupez les tomates, les oignons et l’ail et condiments vert en petits dés, réservez-les."
            },
            {
            text:"Écossez les haricots et coupez-les en petits dés, réservez-les."
            },
            {
            text:"Mettez de l’huile Cel’or  dans une poêle et faites bien dorer les morceaux de poulets sur chaque  face et rangez les tout près."
            },
            {
            text:"Coupez les plantains en rondelles et faites les frire avec le reste d’huile de poulet."
            },
            {
            text:"Ensuite, faites revenir  les tomates et les oignons dans une casserole avec un peu d’huile."
            },
            {
            text:"Ajoutez les poivrons,l’ail, les condiments coupé en dé et après quelques minutes de cuisson, mettez les carottes et les haricots vert."
            },
            {
            text:"Assaisonnez avec le cube, les épices écraser, ajoutez le piment et un verre d’eau."
            },
            {
            text:"Ajoutez progressivement les morceaux de poulet et de plantain mis de côté, puis remuez."
            },
            {
            text:"Laissez cuire encore 10 minutes."
            },
            {
            text:"Goûtez l’assaisonnement."
            },
            {
            text:"C’est prêt. Servez chaud, bon appétit !"
            },
        ],
        createdAt: '15 Mai 2020', 
        cover:'/assets/images/reciepes/celor-poulet-dg-medium.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-poulet-dg2.jpeg',
            '/assets/images/reciepes/celor-poulet-dg2.jpeg'
      ],
    },
    {
        id: 9,
        title: 'Cel’or et son ',
        title2: 'Gésier de poulet rôtis.',
        shortTitle:"le rôtis de gésier de poulet.",
        preparationTime: '30 Minutes',
        cookTime: '45 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"1 kg de gésiers nettoyés."
            },
            {
              img_url: "",
              ingredient:"5 oignons rouges."
            },
            {
              img_url: "",
              ingredient:"Un mélange poivron frais (vert rouge jaune)"
            },
            {
              img_url: "",
              ingredient:"1 cuillère à café de thym séché"
            },
            {
              img_url: "",
              ingredient:"1 cuillère à café de gingembre en poudre"
            },
            {
              img_url: "",
              ingredient:"1 cuillère à café de poudre d’ail"
            },
            {
              img_url: "",
              ingredient:"8 tomates"
            },
            {
              img_url: "",
              ingredient:"quelques feuilles de basilic"
            },
            {
              img_url: "",
              ingredient:"1/4itre d’huile de cuisson Cel’or"
            },
        ],
        steps : [
            {
            text:"Comment nettoyer un gésier avant de le cuire ?."
            },
            {
            text:"Commencez par enlever le gras qui le couvre."
            },
            {
            text:"Coupez-le ensuite en deux."
            },
            {
            text:"Videz-le de son contenu et nettoyer à grande eau."
            },
            {
            text:"A l’intérieur vous trouverez une membrane jaune d’aspect caoutchouteux, si vous pouvez, débarrasser-vous d’elle."
            },
            {
            text:"Votre gésier est prêt à être cuisiné."
            },
            {
            text:"Coupez les gésiers en morceaux de la taille de votre choix."
            },
            {
            text:"Dans une casserole, mettre les gésiers avec 2 oignons émincés, la moitié des poivrons coupés en dés, le thym séché, le gingembre, le basilic et l’ail en poudre. Assaisonner avec le sel et couvrez d’eau."
            },
            {
            text:"Portez le tout à ébullition à feu moyen pendant une demi-heure, jusqu’à ce que les gésiers soient cuits mais encore croquants."
            },
            {
            text:"Pendant la cuisson, préparez la sauce tomate : mixez les tomates hachées,2 oignons et le reste des poivrons."
            },
            {
            text:"Faites chauffer une casserole avec de l’huile de cuisson Cel’or et faites y revenir un oignon émincés, avec une pincée de sel, environ 5 minutes."
            },
            {
            text:"Ajoutez le mélange de tomates et cuire à feu moyen pendant 10 minutes."
            },
            {
            text:"Toute l’eau de la tomate doit s’évaporer."
            },
            {
            text:"Faites mijoter la sauce tomate et les gésiers égouttés à feu moyen durant 5 minutes, en mélangeant de temps en temps."
            },
            {
            text:"Goûter l’assaisonnement."
            },
            {
            text:"c’est prêt!"
            },
            {
            text:"Servez mélangé à des pommes ou des plantains frits.."
            },
            {
            text:"L’huile Cel’or vous souhaite un bon appétit!."
            },
        ],
        createdAt: 'June 03, 2021', 
        cover:'/assets/images/reciepes/celor-gesier-de-poulet.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-gesier-de-poulet1.jpeg',
            '/assets/images/reciepes/celor-gesier-de-poulet2.jpeg'
      ],
    },
    {
        id: 10,
        title: 'Spécial Pâques, Cel’or et son ',
        title2: 'Poulet yassa.',
        shortTitle:"Le Poulet yassa Sénégalais!",
        preparationTime: '2h:10 Minutes',
        cookTime: '45 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"1 poulet entier, coupé en morceaux de tailles moyennes"
            },
            {
              img_url: "",
              ingredient:"300g d’aubergines"
            },
            {
              img_url: "",
              ingredient:"7 oignons, tranchés"
            },
            {
              img_url: "",
              ingredient:"Dus jus de 4 citrons"
            },
            {
              img_url: "",
              ingredient:"2 cuillères à soupe de moutarde, de préférence de la moutarde de Djion"
            },
            {
              img_url: "",
              ingredient:"1 tête d’ail"
            },
            {
              img_url: "",
              ingredient:"2 cuillères à soupe de poivre noir ou blanc"
            },
            {
              img_url: "",
              ingredient:"1/4 cuillère à soupe de poudre de piment"
            },
            {
              img_url: "",
              ingredient:"1/2 d’huile raffinée Cel’or"
            },
            {
              img_url: "",
              ingredient:"Du sel, selon votre goût"
            },
        ],
        steps : [
            {
            text:"Marinez le poulet avec 1 cuillère à soupe de moutarde, la moitié de l’ail, 2 citrons, du poivre noir, puis conserver dans le réfrigérateur pendant au moins 2 heures."
            },
            {
            text:"Tranchez et marinez les oignons avec le reste du moutarde, les citrons, le poivre noir (comme vous l’avez fait avec le poulet)."
            },
            {
            text:"Dans une casserole, chauffez l’huile Cel’or et faites dorez les oignons, puis ajouter le reste d’ail, le poulet grillé, 2 tasses d’eau, les cubes, le piment et salez."
            },
            {
            text:"Laissez mijotez pendant 30 ou 40minutes jusqu’à ce que le poulet soit prêt et que le liquide contenu dans la sauce soit presque totalement absorbé."
            },
            {
            text:"Goûtez l’assaisonnement."
            },
            {
            text:"C’est prêt!."
            },
            {
            text:"Vous pouvez servir avec du riz ou un complément de votre choix."
            },
        ],
        createdAt: '16 avril 2020', 
        cover:'/assets/images/reciepes/celor-poulet-yassa-medium.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-poulet-yassa2.jpeg',
            '/assets/images/reciepes/celor-poulet-yassa2.jpeg'
      ],
    },

    {
        id: 11,
        title: 'Voici comment réussir son ',
        title2: 'Mbongo Tchobi avec Cel’or.',
        shortTitle:" le Mbongo Tchobi accomgné d’igname cuire à la vapeur!",
        preparationTime: '30 Minutes',
        cookTime: '45 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        ingredients : [
            {
              img_url: "",
              ingredient:"La poudre noire pour la sauce"
            },
            {
              img_url: "",
              ingredient:"Du pebe"
            },
            {
              img_url: "",
              ingredient:"De rondelle"
            },
            {
              img_url: "",
              ingredient:"De 4 cotés"
            },
            {
              img_url: "",
              ingredient:"D’épice de mbongo"
            },
            {
              img_url: "",
              ingredient:"Du Ndjansang"
            },
            {
              img_url: "",
              ingredient:"Des gousses d’ail"
            },
            {
              img_url: "",
              ingredient:"De tomate presque mure"
            },
            {
              img_url: "",
              ingredient:"Du poisson machoiron ou silures"
            },
            {
              img_url: "",
              ingredient:"Des oignons"
            },
            {
              img_url: "",
              ingredient:"De feuilles de laurier"
            },
            {
              img_url: "",
              ingredient:"De gingembre"
            },
            {
              img_url: "",
              ingredient:"De L’huile raffinée Cel’or"
            },
            {
              img_url: "",
              ingredient:"Du poivre blanc moulu"
            },
            {
              img_url: "",
              ingredient:"Des condiments verts (poireau, céleri et basilic)"
            },
            {
              img_url: "",
              ingredient:"Du sel, cube et un peu de basilic"
            },
        ],
        steps : [
            {
            text:"La première étape consiste à griller à la poêle, les graines de ndjansang, l’épice mbongo, les 4 cotés, le pèbè et la rondelle."
            },
            {
            text:"Ensuite, écrasez le tout, associé ce premier résultat  à la tomate, condiments verts, ail et gingembre et mixez jusqu’à l’obtention d’une purée à peu près homogène."
            },
            {
            text:"La troisième étape quant à elle consiste à Nettoyer le poisson, le sale légèrement, puis le plonger dans mélange écrasé. Ainsi fait, posez votre marmite au feu et laissez mariner pendant une vingtaine de minutes."
            },
            {
            text:"Dans une autre cocotte, Faites chauffez de l’huile Cel’or, dorez les oignons, insérez le composé précédent, plus poivre blanc, feuilles de laurier, sel et cube, puis ajoutez un peu plus d’un demi litre d’eau. Enfin, laissez cuire à feu moyen, pendant ½ heure."
            },
            {
            text:"Après cette demi-heure, révisez votre assaisonnement et votre Mbongo Tchobi est prêt."
            },
            {
            text:"De préférence, servez le chaud, avec comme compléments : du riz, de la banane plantain, du macabo, des tubercules de manioc, et bien d’autres."
            },
            {
            text:"bon appétit !!!!!!! "
            },
        ],
        createdAt: '28 Aout 2020', 
        cover:'/assets/images/reciepes/celor-mbongo-tchobi-medium.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-mbongo-tchobi2.jpeg',
            '/assets/images/reciepes/celor-mbongo-tchobi2.jpeg'
      ],
    },

    {
        id: 12,
        title: 'Cel’or et son ',
        title2: 'Ndole-plantain mûr.',
        shortTitle:"Le Ndole plantain mûr!",
        preparationTime: '25 Minutes',
        cookTime: '35 minutes',
        subCategory: ['ndole', 'poisson', 'epices'],
        description :" ",
        ingredients : [
            {
              img_url: "",
              ingredient:"3 boules de ndolé lavé"
            },
            {
              img_url: "",
              ingredient:"300g d’arachides crues"
            },
            {
              img_url: "",
              ingredient:"600g de crevettes"
            },
            {
              img_url: "",
              ingredient:"1kg de viande de bœuf"
            },
            {
              img_url: "",
              ingredient:"4 gros oignons"
            },
            {
              img_url: "",
              ingredient:"50g de gingembre"
            },
            {
              img_url: "",
              ingredient:"du poivre blanc"
            },
            {
              img_url: "",
              ingredient:"piments"
            },
            {
              img_url: "",
              ingredient:"une gousse d’ail"
            },
            {
              img_url: "",
              ingredient:"le jus de viande"
            },
            {
              img_url: "",
              ingredient:"1/2L d’huile végétale Cel’or"
            },
        ],
        steps : [
            {
                text:"Dans une casserole mettez de la viande découpé en morceau avec du cube et un oignons haché et portez à ébullition pendant 20 minutes."
            },
            {
                text:"Dans une autre petite casserole, mettez les arachides, ajoutez de l’eau et laissez cuire environ 5 minutes après ébullition. Retirez du feu."
            },
            {
                text:"Égouttez les arachides, dépulpé les, puis écrasez-les en purée. Épluchez et lavez 02 oignons, coupez les finement, ensuite, écrasez les oignons avec le gingembre, le piment l’ail et le poivre."
            },
            {
                text:"Faites revenir dans l’huile raffiné Cel’or les ingrédients écrasés pendant 5 minutes, puis ajoutez les arachides, mélangez doucement et portez à ébullition tout en remuant pour éviter que ça colle au fond de la casserole."
            },
            {
                text:"Ajouter ensuite la viande et son jus et laisser mijoter encore 10 minutes et enfin,mettez les boules  de ndole une à une en remuant entre chaque ajout."
            },
            {
                text:"Assaisonnez et rajoutez les crevettes . Laissez cuire à feu très doux pendant 15 minutes et remuez de temps en temps."
            },
            {
                text:"Dans une poêle, faites y revenir le reste d’oignons et versez l’ensemble sur le ndolé."
            },
            {
                text:"Goûtez l’assaisonnement, c’est prêt!"
            },
            {
                text:"Servez avec du plantain mûr cuir à la vapeur, ou avec un complément de votre choix: bâton de manioc, du riz, du macabo etc..."
            },
        ],
        createdAt: '8 Mai 2020', 
        cover:'/assets/images/reciepes/celor-ndole-plantaimn-mur-medium.jpeg',
        cover2: [
            '/assets/images/reciepes/celor-ndole-plantaimn-mur2.jpeg',
            '/assets/images/reciepes/celor-ndole-plantaimn-mur2.jpeg'
      ],
    },

  ];
  