import React, { useEffect } from 'react'
import './ContactUS.css'
import {GoLocation} from 'react-icons/go'
import {BsEnvelope} from 'react-icons/bs'
import {ImPhone} from 'react-icons/im'
import WOW from 'wowjs';




function ContactUS() {


  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])


  const gradientS ={
    backgroundImage: 'linear-gradient(to right, #0067f4 0%, #2bdbdc 50%, #0067f4 100%)',
    backgroundSize: '200% auto',
    overflow: 'hidden',
  }




  return (
    <>

        <section id="google-map-area">
            <div class="mx-6 mb-6 ">
                <div class="flex">
                    <div class="w-full wow fadeInUp" data-wow-delay="0.1s">
                      
                        {/* <object style="border:0; height: 450px; width: 100%;" data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3102.7887109309127!2d-77.44196278417968!3d38.95165507956235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzjCsDU3JzA2LjAiTiA3N8KwMjYnMjMuMiJX!5e0!3m2!1sen!2sbd!4v1545420879707"></object> */}
                        
                    </div>
                </div>
            </div>
        </section>

        <section id="contact" class="py-24">    
          <div class="container">
            <div class="text-center">
              <h2 class="mb-12 text-4xl text-gray-700 font-bold tracking-wide wow fadeInUp" data-wow-delay="0.3s">Contactez Nous</h2>
            </div>

            <div class="flex flex-wrap contact-form-area wow fadeInUp" data-wow-delay="0.4s">          
              <div class="w-full md:w-1/2">
                <div class="contact">
                  <h2 class="uppercase font-bold text-xl text-gray-700 mb-5 ml-3">Ecrivez Nous</h2>
                  <form id="contactForm" action="assets/contact.php">
                    <div class="flex flex-wrap">
                      <div class="w-full sm:w-1/2 md:w-full lg:w-1/2">
                        <div class="mx-3">
                          <input type="text" class="form-input rounded-full" id="name" name="name" placeholder="Name" required data-error="Please enter your name"/>
                        </div>                                 
                      </div>
                      <div class="w-full sm:w-1/2 md:w-full lg:w-1/2">
                        <div class="mx-3">
                          <input type="text" placeholder="Email" id="email" class="form-input rounded-full" name="email" required data-error="Please enter your email"/>
                        </div> 
                      </div>
                      <div class="w-full">
                        <div class="mx-3">
                          <input type="text" placeholder="Subject" id="subject" name="subject" class="form-input rounded-full" required data-error="Please enter your subject"/>
                        </div>
                      </div>
                      <div class="w-full">
                        <div class="mx-3"> 
                          <textarea class="form-input rounded-lg" id="message" name="message" placeholder="Your Message" rows="5" data-error="Write your message" required></textarea>
                        </div>
                      </div>
             
                       <div class="w-full">
                            <div class="mx-4 mt-2 text-center single-form form-group">
                                <button style={gradientS} type="submit" class="main-btn bg-blue-700 gradient-btn focus:outline-none">Envoyer Message</button>
                            </div>
                        </div>
                    </div>            
                  </form>
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div class="ml-3 md:ml-12 wow fadeIn">
                  <h2 class="uppercase font-bold text-xl text-gray-700 mb-5">Joinez Nous</h2>
                  <div>
                    <div class="flex flex-wrap mb-6 items-center">
                      <div class="contact-icon">
                        <GoLocation />
                      </div>
                      <p class="pl-3">Bali, Rue Des Mangiers</p>
                    </div>
                    <div class="flex flex-wrap mb-6 items-center">
                      <div class="contact-icon">
                        <BsEnvelope />
                      </div>
                      <p class="pl-3">
                        <a href="#" class="block">hacc-direction@gmail.com</a>
                        {/* <a href="#" class="block">tomsaulnier@gmail.com</a> */}
                      </p>
                    </div>
                    <div class="flex flex-wrap mb-6 items-center">
                      <div class="contact-icon">
                        <ImPhone />
                      </div>
                      <p class="pl-3">
                        <a href="#" class="block">(00237) 691 543 169</a>
                        {/* <a href="#" class="block">(00237) 691 543 169</a> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </section>
      
      

    </>
  )
}

export default ContactUS