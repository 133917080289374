import React from 'react'
import "./Home.css"
import { Header } from '../';
import { HomeAbout, HomeServices, Navbar, OurPartners, Testimonials } from '../../components';


function Home() {
  return (
    <div className='hacc_home'>
        <Header />
        <HomeAbout />
        <HomeServices />
        <Testimonials />

        <OurPartners />



    </div>
  )
}

export default Home