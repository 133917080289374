export const eventsList = [
    {
        id: 1,
        title: ' Friends Food',
        date: 'Wednesday June 03, 2021',
        cover:'/assets/images/events/celor-au-friends-food/celor-au-frien-foods-large.jpeg',
        cover2: [
            '/assets/images/events/celor-au-friends-food/celor-au-friends-food2.jpeg',
            '/assets/images/events/celor-au-friends-food/celor-au-friends-food3.jpeg'
        ],
        shortDesc:"Ce que nous avons réalisé à la foire Friends Food.",
        description:'HACC Sarl en vue de faire connaitre la marque Cel’or a participé à la dernière foire « People » de FRIENDS FOODS qui s’est tenue à l’esplanade du super marché Carrefour sis à Bonamoussadi, le 23 Février dernier. Entre animation, célébration, et réjouissance, la société HACC SARL à mis un dispositif d’accueil très chaleureux aux personnes venues sur les lieux passer du bon temps, en offrant une dégustation de grillades faites à base de notre princesse l’huile raffinée cel’or sans cholestérol. Ainsi, nous avons pu organiser les jeux concours qui ont permis aux consommateurs de repartir avec divers gadgets tel que les tee-shirt, les bouteilles d’huile Cel’or, les bassines des gobelet... Ce fut une réussite car les consommateurs ont testé et adopter la pureté et légèreté de notre huile végétale 100% pure Raffinée.'
    },
    {
        id: 2,
        title: ' Promote Yaounde 2022',
        date: 'Wednesday June 23, 2022',
        cover:'/assets/images/events/celor-promote-2022/20220219_180825\ Large.jpeg',
        cover2: [
            '/assets/images/events/celor-promote-2022/20220221_093221-large.jpeg',
            '/assets/images/events/celor-promote-2022/20220221_130403-large.jpeg'
        ],
        shortDesc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    


  ];
  