import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import './SingleEvent.css'
import {eventsList} from '../../config/eventsData'
import { EmptyList } from '../../components';

function SingleEvent() {


  const { id } = useParams();
  const [event, setEvent] = useState(null)

  useEffect(() => {
    let event = eventsList.find((event) => event.id === parseInt(id));
    if (event) {
        setEvent(event);
    }
  }, [id]);


  return (    
    <div className='mt-5 mb-5 m-4'>


        <Link className='blog-goBack' to='/evenements'>
            <span> &#8592;</span> <span>Retour</span>
        </Link>

        {event ? (
            <>
                <div className="container">
                    <div class="justify-center row">
                        <div class="w-full mx-4 lg:w-1/2">
                            <div class="pb-9 text-center section-title wow fadeInUp">
                                <h4 class="title"> Cel'or & <span className="font-bold text-yellow-400"> {event.title} </span> </h4>
                                <p class="text">Notre oeuvre!</p>
                            </div>
                        </div>
                    </div> 
                </div>

                <div className="container pb-5">
                    <div class="justify-center row">
                        <div class="pb-1 wow fadeInUp text-left grid grid-rows-4 grid-cols-4 grid-flow-col gap-4">
                            <div class="row-span-4 col-span-3 g-0 order-0 order-md-1"><img class="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src={event.cover} alt="Celor Au Friends Food" /></div>
                            <div class="row-span-2 col-span-2 g-0 order-1 order-md-2"><img class="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src={event.cover2[0]} alt="Celor Au Friends Food" /></div>
                            <div class="row-span-2 col-span-2 g-0 order-2 order-md-3"><img class="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src={event.cover2[1]} alt="Celor Au Friends Food" /></div>
                        </div>
                    </div> 
                </div>

                <div className="container">
                    <div class="justify-center row">
                        <div class="pb-1 wow fadeInUp text-left">
                            <h4 class="text-4xl font-bold">{event.shortDesc} </h4>
                            <h4 class="text-4xl font-bold"> {event.date}  </h4>
                            <p className='mt-5'>
                                {event.description}
                            </p>
                        </div>
                    </div> 
                </div>
            </>

            ) : (
            <EmptyList />
        )}



    </div>
  )
}

export default SingleEvent