import React, { useEffect } from 'react'
import './OurPartners.css'
import WOW from 'wowjs';

function OurPartners() {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])


  return (
    <div className='hacc_ourPartners'>
        <div className="container">
        <div class="justify-center row">
                <div class="w-full mx-4 lg:w-1/2">
                    <div class="pb-10 pt-7 text-white text-center section-title wow fadeInUp">
                        <h4 class="title text-white">Nos Partenaire</h4>
                        <p class="text  text-white">Ils Nous Font Confiances!</p>
                    </div> 
                </div>
            </div> 
        </div>
        <div className='gpt3__brand section__padding'>
            <div className="">
                <img src="/assets/images/home/anor-logo.jpeg" alt="google" />
            </div>
            <div className="">
                <img src="/assets/images/home/socapalm-logo.png" alt="slack" />
            </div>
            <div className="">
                <img src="/assets/images/home/cca-bank-logo.png" alt="atlassian" />
            </div>
            <div className="">
                <img src="/assets/images/home/alliment-enrichie-logo.png" alt="shopify" />
            </div>
        </div>
    </div>
  )
}

export default OurPartners