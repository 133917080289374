import React, { useEffect } from 'react'
import "./OurProducts.css"
import { Link } from 'react-router-dom'
import WOW from 'wowjs';


function OurProducts() {


    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])


  return (
    <section id="testimonial" class="testimonial-area py-120">
        <div class="container">            
            <div class="justify-center row">
                <div class="w-full mx-4 lg:w-1/2">
                    <div class="pb-10 text-center section-title wow fadeInUp">
                        <h4 class="title">Nos Produits</h4>
                        <p class="text">Ici Vous sont illustrer les produit de HACC!</p>
                    </div> 
                </div>
            </div> 
            
            <div class="row">
                <div class="w-full wow fadeInLeft">
                    <div class="row ">

                    
                        <div className="w-full lg:w-1/3 md:w-1/2  wow fadeInLeft ">
                            <div class="product mx-3 mb-6 shadow-xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                <span class="product__price">$120</span>
                                <img class="product__image" src="/assets/images/product/fakSoap.png"/>
                                <h1 class="product__title">Huile Celor</h1>
                                <hr />
                                <p>Huile Celor est Une huile rafiné de Palm, riche en vitamin C, exelent pour la sante et a consomé pas trop cuit pour bien juire de ces bien faits </p>
                                <Link to="/produits/1" class="rounded-full btn mt-3 gradient-btn bg-blue-700 transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110">Commandez</Link>
                            </div>
                        </div>
                        <div className="w-full  lg:w-1/3 md:w-1/2  wow fadeInUp">
                            <div class="product mx-3 mb-6 shadow-xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                <span class="product__price">$120</span>
                                <img class="product__image" src="/assets/images/product/celor-1L@2x.png" />
                                <h1 class="product__title">Huile Celor</h1>
                                <hr />
                                <p>Huile Celor est Une huile rafiné de Palm, riche en vitamin C, exelent pour la sante et Link consomé pas trop cuit pour bien juire de ces bien faits </p>
                                <Link to="/produits/2" class="rounded-full gradient-btn btn mt-3 bg-blue-700 transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110">Commandez</Link>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 md:w-1/2  wow fadeInDown ">
                            <div class="product mx-3 mb-6 shadow-xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                <span class="product__price ">$120</span>
                                <img class="product__image" src="/assets/images/product/fakSoap2.png" />
                                <h1 class="product__title">Huile Celor</h1>
                                <hr />
                                <p>Huile Celor est Une huile rafiné de Palm, riche en vitamin C, exelent pour la sante et Link consomé pas trop cuit pour bien juire de ces bien faits </p>
                                <Link to="/produits/3" class="btn rounded-full bg-blue-700 gradient-btn mt-3 transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110">Commandez</Link>
                            </div>
                        </div>


                    </div> 
                </div>
            </div> 
        </div> 
    </section>
  )
}

export default OurProducts