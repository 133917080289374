import React, { useEffect } from 'react'
import './HomeServices.css'
import {FcMoneyTransfer, FcShipped} from 'react-icons/fc'
import WOW from 'wowjs';

function HomeServices() {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

  return (
    <>

        <section id="service" class="relative bg-gray-100 services-area py-40  ">
            <div class="container">

                <div class="flex flex-wrap">
                    <div class="w-full mx-4 lg:w-1/2">
                        <div class="pb-10 section-title wow fadeInUp">
                            <h4 class="title ">Nos Services</h4>
                            <p class="text ">La HACC permet à toute sa Clientèle les Services Suivant !</p>
                        </div> 
                    </div>
                </div> 

                <div class="flex flex-wrap">
                    <div class="w-full lg:w-2/3">
                        <div class="row">
                            <div class="w-full md:w-1/2">
                                <div class="block mx-4 services-content sm:flex wow slideInLeft">
                                    <div class="services-icon">
                                        {/* <FaMoneyCheck /> */}
                                        <FcMoneyTransfer />
                                    </div>
                                    <div class="mb-8 ml-0 services-content media-body sm:ml-3 ">
                                        <h4 class="services-title">Achat</h4>
                                        <p class="text">Vous pouvez désormais acheter n’importe quel produit de la HACC sur notre plateforme.</p>
                                    </div>
                                </div> 
                            </div>
                            {/* <div class="w-full md:w-1/2">
                                <div class="block mx-4 services-content sm:flex">
                                    <div class="services-icon">
                                        <i class="lni-bar-chart"></i>
                                    </div>
                                    <div class="mb-8 ml-0 services-content media-body sm:ml-3">
                                        <h4 class="services-title">SaaS Business</h4>
                                        <p class="text">Short description for the ones who look for something new.</p>
                                    </div>
                                </div> 
                            </div>
                            <div class="w-full md:w-1/2">
                                <div class="block mx-4 services-content sm:flex">
                                    <div class="services-icon">
                                        <i class="lni-brush"></i>
                                    </div>
                                    <div class="mb-8 ml-0 services-content media-body sm:ml-3">
                                        <h4 class="services-title">Agency</h4>
                                        <p class="text">Short description for the ones who look for something new.</p>
                                    </div>
                                </div> 
                            </div> */}
                            <div class="w-full md:w-1/2">
                                <div class="block mx-4 services-content sm:flex wow slideInLeft">
                                    <div id='services-icon' class="services-icon">
                                        <FcShipped />
                                    </div>
                                    <div class="mb-8 ml-0 services-content media-body sm:ml-3">
                                        <h4 class="services-title">Livraison</h4>
                                        <p class="text">Vous pouvez désormais vous faire Livrez vos produits commander via plateforme.</p>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div> 
                </div> 
            </div> 
            <div class="services-image">
                <div class="image wow slideInRight">
                    <img src="/assets/images/home/services.png" alt="Services"/>
                </div>
            </div> 
        </section>

    </>
  )
}

export default HomeServices