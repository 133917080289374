import React from 'react'
import './Products.css'
import { Link } from 'react-router-dom'

function Products() {
  return (
    <>

        <section className="product-detail  py-30 mx-4 my-8 h-200 flex flex-wrap justify-evenly content-center shadow-2xl">

            <div className="card-image lg:w-1/2 h-full  ">
                {/* <div className="image-div rounded-full bg-white w-30 h-80"> */}
                    <img src="/assets/images/product/celor-1L@2x.png" alt="Product Image"  className='mx-auto transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110' />

                {/* </div> */}
            </div>
            <div className="card-infos   lg:w-1/2 h-full shadow-xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                <div className="flex flex-col justify-start items-start my-7 mx-4 ">
                    <p className='text-3xl font-semibold mb-2'>Pac Carton Cel'or 1L Oil</p>
                    <span className='text-3xl font-extrabold text-yellow-300 mb-2'>150000XAF</span>
                    <h3 className='text-base mb-2'>Description</h3>
                    <hr />
                    <p className='description text-gray-400 mb-6 font-mono'>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. In quidem, eius sequi placeat quos harum. Aut quibusdam nesciunt voluptas minus, molestiae magnam tempore odit, corporis adipisci aliquam quae perspiciatis exercitationem!
                    </p>

                    <li className='mb-2'> <span className='text-gray-500'>Quantity </span> : <input type="number" className='w-20 border-2 rounded-lg' /></li>

                    <Link to="/commande" class="btn gradient-btn rounded-full my-2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" href="#">Ajouter Au Panier</Link >
                </div>

            </div>

        </section>

    </>
  )
}

export default Products